@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.root {
	max-width: 100rem;
	margin: 0 auto;
	padding: 180px 75px 132px;

	display: flex;
	flex-direction: column;
	gap: 80px;

	color: #fff;

	@include sm-down {
		padding: 70px 24px;
		gap: 56px;
	}
}

.content_container {
	display: flex;
	flex-direction: column;
	align-items: center;

	text-align: center;

	h2 {
		max-width: 23ch;
	}

	p {
		max-width: 40ch;
		color: #a6a6a6;
	}
}
.image_container {
	position: relative;

	box-shadow: 0px 47px 104px 0px rgba(0, 0, 0, 0.2);

	img {
		border-radius: 25px;

		@include sm-down {
			border-radius: 8px;
		}
	}
}

.fitness_score_container {
	position: absolute;
	top: 32px;
	right: 32px;
	width: 208px;
	height: 235px;

	display: flex;
	flex-direction: column;

	background: rgba(255, 255, 255, 0.08);
	backdrop-filter: blur(20px);

	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 25px;

	@include sm-down {
		top: -20px;
		right: 20px;
		width: 104px;
		height: 117px;

		backdrop-filter: blur(10px);

		border: 0.5px solid rgba(255, 255, 255, 0.3);
		border-radius: 12.5px;
	}
}

.fitness_score_ellipse_container {
	position: absolute;
	top: 32px;
	left: 24px;
	width: calc(100% - 48px);

	@include sm-down {
		top: 16px;
		left: 12px;
		width: calc(100% - 24px);
	}
}

.fitness_score_title_container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

	display: flex;
	flex-direction: column;
	align-items: center;
}

.fitness_score_title_number {
	@include headline5;
	// TODO(marko): Remove this once the fonts are updated in Figma to only use pre-defined classes
	font-weight: 500;

	color: #25ffbd;

	@include sm-down {
		// TODO(marko): Remove this once the fonts are updated in Figma to only use pre-defined classes
		font-size: 1.25rem;
		font-weight: 500;
		line-height: normal;
	}
}

.fitness_score_title_text {
	@include eyebrow1;
	color: #25ffbd;

	@include sm-down {
		// TODO(marko): Remove this once the fonts are updated in Figma to only use pre-defined classes
		font-size: 0.375rem;
		font-weight: 500;
		line-height: normal;
	}
}

.fitness_score_subtitle {
	position: absolute;
	bottom: 24px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;

	@include body4;
	text-align: center;

	@include sm-down {
		bottom: 12px;

		// TODO(marko): Remove this once the fonts are updated in Figma to only use pre-defined classes
		font-size: 0.5rem;
		font-weight: 400;
		line-height: normal;
	}
}

.athlete_container {
	position: absolute;
	bottom: 52px;
	left: 52px;

	display: flex;
	flex-direction: column;
	gap: 4px;

	@include sm-down {
		bottom: 16px;
		left: 16px;
	}
}

.athlete_name {
	@include headline9;
	// TODO(marko): Remove this once the fonts are updated in Figma to only use pre-defined classes
	font-weight: 700;

	@include sm-down {
		@include headline9;
	}
}

.athlete_occupation {
	@include body5;
}
