@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background: radial-gradient(circle at 0 0, #4f6979 0%, $black 100%);
	color: $white;

	min-height: 100svh;
	max-height: 100lvh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: relative;

	text-align: center;

	isolation: isolate;

	padding: 2rem;
}

.container_newstyle {
	color: $white;

	min-height: 100svh;
	max-height: 100lvh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	position: relative;

	isolation: isolate;

	padding: 2rem 5rem;

	@include sm-down {
		padding: 8rem 2rem;
		justify-content: flex-start;
		align-items: center;
	}
}

.container_left_aligned {
	@include md-up {
		align-items: flex-start;
	}
}

.container_mobile_top {
	@include sm-down {
		justify-content: flex-start;
		padding: 8rem 0;
	}
}

.tag {
	display: inline-flex;
	@include eyebrow1;

	color: $white;
	//color: #5289fe;

	border-radius: 0.25rem;
	//	background: rgba(82, 137, 254, 0.3);

	padding: 0.25rem 0.5rem;
	margin-bottom: 1.5rem;
}

.eyebrow {
	@include eyebrow1;
	margin-bottom: 1rem;

	@include sm-down {
		@include eyebrow2;

		margin-bottom: 0.75rem;
	}
}

.cta_container {
	display: flex;

	align-items: center;
	gap: 0.5rem;
	margin-top: 3.5rem;

	@include sm-down {
		gap: 0.25rem;
		flex-direction: column;
		margin-top: 2rem;
	}
}

.video_button {
	color: $white !important;

	padding: 1rem !important;
	border-radius: 0.5rem !important;

	span {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		@include button1;
		color: $white !important;
	}

	svg {
		width: 2rem;
		height: 2rem;
	}

	&:hover,
	&:focus-visible {
		background: transparentize(white, 0.9);
	}
}

.video_container {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 100%;

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.header_newstyle {
	text-align: left;
	h2 {
		@include headline3;
		font-size: 4.5rem; // TODO out of spec
		max-width: 12ch;
		margin-bottom: 1.5rem;
	}

	p {
		@include body2;

		max-width: 36ch;
		margin-bottom: 3rem;
	}

	@include sm-down {
		text-align: center;

		h2 {
			@include headline5;
			margin-bottom: 1.5rem;
			margin-inline: auto;
		}

		p {
			@include body3;
			max-width: 25ch;
			margin-inline: auto;
			margin-bottom: 2rem;
		}
	}
}

.container header {
	h2 {
		@include headline2;
	}

	p {
		@include headline6;

		max-width: 15ch;
		margin-inline: auto;
		margin-top: 1.5rem;
	}

	@include sm-down {
		h2 {
			@include headline5;
			//margin-bottom: 1.5rem;
			padding: 0 1.5rem;
		}

		p {
			@include headline8;
		}
	}
}

.header_left_aligned {
	@include md-up {
		text-align: left;
	}
}

.header_text_theme_black {
	color: $black;
}

.content_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	video,
	img,
	picture,
	figure {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	z-index: -2;
}

.walking_hero {
	img {
		object-position: 33%;
	}
}

.anchor_link_wrapper {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);

	img {
		width: 32px;
	}
}

.shade {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: -1;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
}

/**
* SPECIFIC STYLES
*/

.clinical_image {
	@include sm-down {
		img {
			object-position: 60% center;
		}
	}
}
.cta_column {
	flex-direction: column;
	gap: 1.5rem;
}
