@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	overflow: hidden;
}

/**

Slider Styles

 */

.slider_wrapper {
	position: relative;
	height: 100%;
	width: 100%;

	overflow: hidden;
}

.items {
	list-style: none;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;

	overflow: visible;

	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	margin: 0;
	li {
		transition: all 0.25s ease-out;
		position: absolute;

		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

/**

Slider Controls

 */

.slider_controls {
	display: flex;
	justify-content: space-between;
	position: absolute;
	gap: 0.5rem;
	width: 100%;
	left: 0;

	top: 50%;
	transform: translateY(-50%);

	padding: 0 1rem;

	div {
		transition: all 0.25s ease-out;
	}

	button {
		border-radius: $br-circle;
		background-color: rgba(255, 255, 255, 1);

		padding: 0.75rem;

		cursor: pointer;

		&:hover,
		&:focus-visible {
			scale: 1.01;

			box-shadow: rgba(0, 0, 0, 0.2) 0 1px 2px 0.5px;
		}

		&:active {
			scale: 0.99;
			box-shadow: none;
		}

		span {
			line-height: 0;
		}
	}

	@include sm-down {
		opacity: 0.5;
	}
}

/**

Card Styles

 */

.card {
	border-radius: 1rem;

	height: 100%;
	color: $white;
	width: 100%;

	margin: auto;
	isolation: isolate;
	display: flex;
	align-items: center;
}

.cardSingle {
	position: absolute;

	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	border-radius: 1rem;

	color: $white;

	isolation: isolate;
	display: flex;
	align-items: center;
}

.tag {
	position: absolute;
	padding: 0.25rem 0.75rem;

	@include eyebrow1;
	text-transform: uppercase;

	top: 0;
	right: 0;

	margin: 2rem;

	border-radius: 0.5rem;
	border: 1px solid $white;
}

.bg_image {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		margin: auto;
	}
}

.quote {
	position: absolute;
	bottom: 0;
	left: 0;

	margin: 2rem;
}

.dot {
	display: inline-flex;
	width: 0.5rem;
	height: 0.5rem;
	background: #a6a6a6;
	text-decoration: none;
	align-items: center;
	justify-content: space-between;
	border-radius: 50%;
	margin: 0 0.25rem;
	cursor: pointer;
}

.dot_selected {
	background: $white;
}

.dots {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 0.5rem;
}
