@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;
	height: 100%;
	display: flex;
	@include sm-down {
		padding: 1.5rem;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	max-height: 100vh;
	width: 100%;
	justify-content: space-between;
	gap: 3rem;

	@include sm-down {
		gap: 2rem;
		overflow: auto;
		justify-content: flex-start;
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: 11.25rem;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #dddcdb;
	max-width: 50rem;

	justify-content: space-between;

	@include sm-down {
		gap: 0;
	}
}

.tab {
	@include headline7;
	position: relative;

	padding: 0.5rem 0;
	cursor: pointer;
	width: fit-content;

	@include sm-down {
		@include headline8;
		padding: 0.5rem 0;
	}

	@include xs-only {
		@include headline9;
		padding: 0.5rem 0;
	}

	opacity: 0.25;
	transition: all 0.25s ease-out;
}

.tab_selected {
	opacity: 1;

	box-shadow: black 0 1px;
}

.tabsWrapper {
	@include sm-down {
		width: 100%;
		overflow-x: auto;
		height: auto;
	}
}

.tabSelected {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 100%;
	box-shadow: black 0 1px;

	transition: border-bottom-color 0.25s ease-out;
}

.tabUnselected {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 100%;
	margin-bottom: -1px;
	border-bottom: 1px solid transparent;

	transition: border-bottom-color 0.25s ease-out;
}

.tabDescriptionWrapper {
	max-width: 750px;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: center;
	flex: 0 0 320px;
	height: 320px;
	justify-content: flex-start;
	@include sm-down {
		width: 100%;
		text-align: left;
		height: auto;
	}
}

.tabDescription {
	text-align: center;

	align-items: start;

	display: grid;
	grid-template-areas: 'center';

	* {
		grid-area: center;
	}

	p {
		@include body1;
		max-width: 50ch;
		@include sm-down {
			@include body2;
		}

		@include xs-only {
			@include body3;
		}

		transition: all 0.25s ease-out;
	}

	@include xs-only {
		text-align: left;
	}
}

.mediaContainer {
	width: 100%;
	height: calc(100vh - 600px);
	background-color: #f2f2f2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex: 2;

	position: relative;

	@include sm-down {
		border-radius: 8px;
		height: 50%;
		flex: unset;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	@include xs-only {
		max-height: 25rem;
	}
}

.media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	transition: all 0.25s ease-out;

	&:last-child {
		img {
			object-position: bottom;
		}
	}
}

.slider {
	display: none;
	:global(.slick-slide) {
		justify-content: center;
		height: unset;
		div {
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	:global(.slick-dots) {
		display: block;
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;

		@include sm-down {
			bottom: 2rem;
		}
	}
}

.visible {
	//	height: 100%;
	display: flex;
	//	align-items: center;
	//	justify-content: center;
}

.tabFooter {
	@include body4;
	color: #a6a6a6;
}

.mediaWrapper {
	img {
		position: relative;
		height: calc(100vh - 425px);
		@include sm-down {
			height: unset;
		}
	}
}
