@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
.root {
	position: relative;
	height: 100svh;

	color: #fff;
}

.content_container {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	@include section-padding;
}

.title {
	max-width: 14ch;
	text-align: center;
}

.button {
	margin-top: 2rem;
}

.video_container {
	position: relative;
	height: 100%;

	* {
		object-fit: cover;
		height: 100%;
	}

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;

		background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.35) 100%);
	}
}

.scroll_down {
	position: absolute;
	bottom: 80px;
	left: 50%;
	transform: translateX(-50%);

	width: 60px;
	height: 60px;

	cursor: pointer;
}

.modal_video_container {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 100%;

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
