@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

$max-width: 80rem;
.container {
	background-color: $white;
	color: $black;

	text-align: center;

	> * {
		max-width: $max-width;

		margin-inline: auto;
	}

	padding: 8rem 2rem;

	@include sm-down {
		padding: 4rem 1.5rem;
	}
}

.container header {
	h2 {
		@include headline3;
		max-width: 20ch;

		margin-inline: auto;
	}

	p {
		@include blue_tag;
		margin-bottom: 1.5rem;
	}

	margin-bottom: 4rem;

	@include sm-down {
		h2 {
			@include headline5;
		}

		margin-bottom: 2rem;
	}
}

.benefits {
	list-style: none;

	margin: 0;
	padding: 0;
}

.video_container {
	aspect-ratio: 16 / 9;

	position: relative;

	max-height: 668px;
	max-width: 100%;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 1rem;
	}
}

.cta_container {
	margin-top: 2rem;
	display: block;
}

.benefits p {
	@include body2;

	max-width: 13.1ch;

	@include sm-down {
		@include body4;
		max-width: 12.9ch;
	}
}

.benefits svg {
	width: 3rem;
	height: 3rem;

	margin-bottom: 1rem;

	@include sm-down {
		width: 2rem;
		height: 2rem;
	}
}
.modal_button {
	background-color: #f2f2f2 !important;
	margin-top: 4rem;
	color: $black !important;
	width: fit-content;
}

.fullScreen {
	inset: 0;
	position: fixed;
	z-index: 1300;

	max-width: unset;

	background: rgba(0, 0, 0, 0.2);

	backdrop-filter: blur(18px);

	transition: all 0.25s ease-out;
}

.full_screen_inner {
	background-color: rgba(255, 255, 255, 0.9);

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border-radius: 1rem;
	overflow: hidden;

	margin: 2rem;

	transition: all 0.25s ease-out;

	@include xs-only {
		margin: 1rem;
	}
}
.closeButton {
	position: absolute;
	top: 0%;
	right: 0%;
	display: flex;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8px);
	padding: 0.5rem;
	border-radius: 0.5rem;
	margin: 1rem;
	width: fit-content;
	cursor: pointer;

	> span {
		display: flex;
	}
}
