@import 'styles/constants';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	:global(.content) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;

		@include sm-down {
			align-items: stretch;
		}

		> div {
			position: relative;
			z-index: 3;
		}

		:global(.animate__animated) {
			opacity: 0;
		}
	}

	@include sm-down {
		min-height: 100vh;

		:global(.background-img) img {
			height: 100vh;
		}
	}

	.logos {
		z-index: 3;
		display: flex;
		column-gap: 60px;
		justify-content: center;
		align-items: stretch;
		margin-bottom: 40px;

		img {
			max-width: 210px;
		}

		> div {
			border-radius: 16px;
			background: rgba(255, 255, 255, 0.25);
			backdrop-filter: blur(20px);
			display: flex;
			align-items: center;
			padding: 36px 28px;
		}

		@include sm-down {
			padding: 0 24px;
			column-gap: 20px;

			img {
				max-width: 100%;
			}

			> div {
				padding: 16px;
			}
		}
	}

	.heading {
		text-align: center;

		z-index: 3;

		padding: 0 2rem;
	}

	.subline {
		text-align: center;
		max-width: 545px;
		margin-inline: auto;

		margin-bottom: 5rem;
		padding: 0 2rem;
	}

	&::after {
		inset: 0;
		z-index: 1;
		position: absolute;
		content: '';
		pointer-events: none;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.66) 69.5%, #000 100%);
	}
}
